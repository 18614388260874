import Home from "../components/Home";
import GameDev from "../components/GameDev";
import Art from "../components/Art"
import Va from "../components/Va"
import Contact from "../components/Contact"

export const ROUTES = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/gamedev",
    element: <GameDev />,
  },
  {
    path: "/art",
    element: <Art />,
  },
  {
    path: "/va",
    element: <Va />,
  },
  {
    path: "/contact",
    element: <Contact />,
  }
];