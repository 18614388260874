import '../App.css';

function Contact() {
  return (
    <div className="Contact">
        <p className="TextPrimary">
          Email: christianyo1234@gmail.com
        </p>
    </div>
  );
}

export default Contact;
