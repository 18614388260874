import { RouterProvider } from "react-router-dom";
import { router } from "./router"
import './App.css';

function App() {

  return (
    <div>
      <div className="HeaderBar">
        <a href="/" className="HeaderBarOption">
          <img className='Icon' src='/images/home.png' alt=""></img>
          <span className="LinkPrimary">Home</span>
        </a>
        <a href="/gamedev" className="HeaderBarOption">
          <img className='Icon' src='/images/console-controller.png' alt=""></img>
          <span className="LinkPrimary">Game Development</span>
        </a>
        <a href="/art" className="HeaderBarOption">
          <img className='Icon' src='/images/palette.png' alt=""></img>
          <span className="LinkPrimary">Art</span>
        </a>
        <a href="/va" className="HeaderBarOption">
          <img className='Icon' src='/images/user-speaking.png' alt=""></img>
          <span className="LinkPrimary">Voice Acting</span>
        </a>
        <a href="/contact" className="HeaderBarOption">
          <img className='Icon' src='/images/envelope.png' alt=""></img>
          <span className="LinkPrimary">Contact</span>
        </a>
      </div>
      <div className='Container'>
        <RouterProvider router={router}/>
      </div>
    </div>
  );
}

export default App;
