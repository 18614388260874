import '../App.css';

function GameDev() {
  return (
    <div className="GameDev">
        <p className="TextPrimary">
          Work in progress, check back later
        </p>
    </div>
  );
}

export default GameDev;
