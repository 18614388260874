import '../App.css';

function Home() {
  return (
    <div className='Home'>
        <p className='TextPrimary'>
          Welcome!
        </p>
        <div className='Intro'>
          <img className='ProfileImage' src='/images/profile.jpg' alt=''></img>
          <span className='TextPrimary'>Welcome to the official site for Christian. <br></br> Here you'll find a showcase of his various passions. <br></br><br></br>Navigate with the tabs at the top. For business inquiries, please reach out at the contact section!</span>
        </div>
    </div>
  );
}

export default Home;
