import '../App.css';

function Art() {
  return (
    <div className="Art">
        <p className="TextPrimary">
          Here is all of Christian's artwork, primarily made in Blender, with some work in Photoshop.
        </p>
    </div>
  );
}

export default Art;
